<template>
  <div class="transactions-page">
    <div class="spinner-container" v-if="!doneInitialLoad">
      <b-spinner></b-spinner>
    </div>
    <div class="content" v-if="doneInitialLoad">
      <div class="controls">
        <BaseButton
          variant="transparent"
          @click="
            $router.push({
              name: 'home',
            })
          "
          size="sm"
          aria-label="back"
        >
          <SVGIcon icon="chevron-left"></SVGIcon>
          <span class="text">Back</span>
        </BaseButton>
        <div
          class="tab"
          @click="state = 'TRANSACTIONS'"
          :class="{ '-active': state === 'TRANSACTIONS' }"
        >
          Transactions
        </div>
        <div
          class="tab"
          @click="state = 'DECLINES'"
          :class="{ '-active': state === 'DECLINES' }"
        >
          Declines
        </div>
      </div>
      <TransactionListComponent
        :transactionsProp="
          state === 'DECLINES' ? transactionList.declines : transactionList.all
        "
        :showDateBuckets="true"
        :showThumbnails="true"
        :showDeclines="state === 'DECLINES'"
        :nodePaginate="true"
        @click-transaction="onClickTransaction"
      >
      </TransactionListComponent>
      <CardInspector
        id="card-inspector"
        @switch="switchCurrentCard"
        :transactionProp="currentTransaction"
        :cardProp="currentCard"
      >
      </CardInspector>
    </div>
  </div>
</template>
<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import TransactionListComponent from "@/components/TransactionList.vue";
import CardInspector from "@/components/CardInspector.vue";
import { Transaction, TransactionList } from "@/types/Transaction";
import { cardStore, transactionStore } from "@/store";
import SVGIcon from "@/components/SVGIcon.vue";
import { Toast } from "@/mixins/Toast";
import { Card } from "@/types/Card";
import { AxiosError } from "@/lib/axios";

const INITIAL_LIMIT = 20;

@Component({
  components: { TransactionListComponent, CardInspector, SVGIcon },
})
export default class Transactions extends Mixins(Toast) {
  state = "TRANSACTIONS";
  doneInitialLoad = false;
  transactionList: TransactionList = { all: [], declines: [] };
  currentTransaction: Transaction | null = null;
  currentCard: Card | null = null;

  async created() {
    try {
      const [, transactions] = await Promise.all([
        cardStore.actions.getCards({}),
        transactionStore.actions.fetchTransactions({
          limit: INITIAL_LIMIT,
        }),
      ]);

      this.transactionList = transactions;
    } catch (error) {
      if ((error as AxiosError)?.response?.status !== 401) {
        this.errorToast("Error loading Transactions page.");
        this.$router.push({ name: "home" });
      }
    } finally {
      this.doneInitialLoad = true;
    }
  }

  onClickTransaction(transaction: Transaction) {
    this.currentTransaction = transaction;
    this.$bvModal.show("card-inspector");
  }

  switchCurrentCard(cardID: number): void {
    const oldCard = cardStore.getters.getCard(cardID);
    if (oldCard) {
      this.currentCard = oldCard;
      this.currentTransaction = null;
    }
  }
}
</script>
<style lang="scss" scoped>
.transactions-page {
  padding-top: 60px;
  display: flex;
  flex-direction: column;

  > .spinner-container {
    position: relative;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
    min-height: calc(100vh - 60px);
  }

  > .content {
    > .controls {
      width: 880px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin: 35px auto 50px;

      @media #{$media-phone} {
        height: 62px;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 0 20px;
        box-shadow: inset 0 -1px 0 $color-shadow-black-faint;
      }
    }

    > .controls > .tab {
      font-family: $font-stack-wes-fy;
      font-size: 18px;
      color: $gray-400;
      transition: color $duration-shorter $ease-out-expo;
      cursor: pointer;

      + .tab {
        margin-left: 30px;
      }

      &.-active,
      &:hover {
        color: $gray-800;
      }

      @media #{$media-phone} {
        padding: 20px 0;
        font-family: $font-stack-lato;
        font-size: 16px;
        font-weight: bold;
        margin-left: 50px;

        &.-active {
          box-shadow: inset 0 -1px 0 $gray-800;
        }
      }
    }

    > .controls > .btn {
      position: absolute;
      left: 0;

      @media #{$media-phone} {
        > .text {
          display: none;
        }
      }
    }

    ::v-deep .transaction-list {
      padding-left: 20px;
      padding-right: 20px;
      margin: -20px auto 20px;
      width: 880px;
    }

    @media #{$media-width-900} {
      ::v-deep .transaction-list,
      > .controls {
        width: 626px;
      }
    }

    @media #{$media-phone} {
      ::v-deep .transaction-list {
        padding-top: 20px;
      }

      > .transaction-list,
      > .controls {
        width: unset;
      }

      ::v-deep .transaction-list .transaction > .left,
      ::v-deep .transaction-list .transaction > .right {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 50px;
      }

      ::v-deep .transaction-list .transaction > .left > .descriptor {
        max-width: 100%;
      }

      ::v-deep .transaction-list .transaction > .right {
        align-items: flex-end;
      }
    }
  }
}
</style>
