import axios, { AxiosError, AxiosResponse } from "@/lib/axios";
import { formatDate } from "@/lib/dates";
import FileSaver from "file-saver";
import {
  Transaction,
  TransactionFilterState,
  TransactionList,
} from "@/types/Transaction";
import { FormatOption } from "@/types/Statement";
import { DisputeType } from "@/types/Dispute";

import { MutationHandler, ActionHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

type TransactionState = {
  cached: { [key: string]: Transaction | null };
  lists: { [listKey: string]: TransactionList };
};

type TransactionListResponse = {
  data: Transaction[];
  total: number;
};

type TransactionParams = {
  cardID?: string | number;
  limit?: number;
  offset?: number;
  query?: string;
  transactionUuid?: string;
};

const builder = storeBuilder.module<TransactionState>("transaction", {
  cached: {},
  lists: {},
});

type TransactionAction<Payload = void, Type = void> = ActionHandler<
  TransactionState,
  RootState,
  any,
  Payload,
  Type
>;

const base = "/api/v1/transaction";
const v2base = "/api/v2/transactions";

type TransactionMutation<Payload = void> = MutationHandler<
  TransactionState,
  Payload
>;

const getTransactions = builder.read(
  (state) =>
    (params: TransactionParams = {}) => {
      const cacheKey = JSON.stringify(params);
      return state.lists[cacheKey];
    },
  "getTransactions"
);

const getTransactionById = builder.read(
  (state) => (id: string | number) => {
    return state.cached[id];
  },
  "getTransactionById"
);

export const getters = {
  get getTransactions() {
    return getTransactions();
  },
  get getTransactionById() {
    return getTransactionById();
  },
};

const setTransactions: TransactionMutation<{
  key: string;
  transactionList: TransactionList;
}> = (state, { key, transactionList }) => {
  state.lists = { ...state.lists, [key]: transactionList };
  const newCached = { ...state.cached };
  for (const t of transactionList.all) {
    newCached[t.transactionID] = t;
  }
  state.cached = newCached;
};

const setTransaction: TransactionMutation<{
  transactionID: string;
  transaction: Partial<Transaction> | Transaction | null;
}> = (state, { transactionID, transaction }) => {
  // Update the transaction in cache
  if (transaction instanceof Transaction) {
    state.cached = { ...state.cached, [transactionID]: { ...transaction } };
  } else if (transaction === null) {
    state.cached = { ...state.cached, [transactionID]: null };
  } else if (state.cached[transactionID]) {
    state.cached = {
      ...state.cached,
      [transactionID]: {
        ...(state.cached[transactionID] as Transaction),
        ...transaction,
      },
    };
  }

  // Update transaction in all the lists it's in
  const newLists = { ...state.lists };

  Object.entries(state.lists).forEach(([key, { all, declines }]) => {
    // Check if list has transaction with transactionID, if not, ignore
    if (!all.find((t) => t.transactionID === transactionID)) {
      return;
    }

    let newList;
    if (transaction) {
      // Create a clone of list but update transaction with new data
      const update = (t: Transaction) => ({ ...t, ...transaction });
      newList = { all: all.map(update), declines: declines.map(update) };
    } else {
      // Create a clone of list but filter out transaction with transactionID
      const filter = (t: Transaction) => t.transactionID !== transactionID;
      newList = { all: all.filter(filter), declines: declines.filter(filter) };
    }

    newLists[key] = newList;
  });

  state.lists = newLists;
};

export const mutations = {
  setTransactions: builder.commit(setTransactions),
  setTransaction: builder.commit(setTransaction),
};

const fetchTransactions: TransactionAction<TransactionParams, any> = (
  { state },
  params = {}
) => {
  const key = JSON.stringify(params);

  if (state.lists[key]) {
    return state.lists[key];
  }

  let path = base;
  const { cardID = "", query, ...queryParams } = params;
  if (cardID) {
    path += "/" + cardID;
  }

  return axios
    .get(path, {
      params: {
        ...queryParams,
        q: query,
      },
    })
    .then(function ({ data }) {
      const transactionList: TransactionList = {
        all: data.transactionList,
        declines: data.declineList,
      };

      if (data.totalTransactions) {
        transactionList.totalTransactions = parseInt(data.totalTransactions);
      }

      mutations.setTransactions({ key, transactionList });

      return transactionList;
    });
};

const fetchTransaction: TransactionAction<
  string,
  TransactionListResponse
> = async (context, transactionUuid: string) => {
  const params = {
    transactionUuid,
    transactionFilter: TransactionFilterState.ALL,
    fullInfo: true,
  };

  try {
    const response = await axios.get(v2base, {
      params,
    });

    return response.data;
  } catch (err) {
    const error = err as AxiosError<{ message?: string }>;
    throw new Error(
      error.response?.data.message || "Error fetching transaction"
    );
  }
};

const hide: TransactionAction<string> = (context, transactionID: string) => {
  return axios.post(base + "/hide/" + transactionID).then(() => {
    mutations.setTransaction({ transactionID, transaction: null });
  });
};

const cancelDispute: TransactionAction<string, any> = (
  context,
  transactionID
) => {
  return axios.post(base + "/dispute/close/" + transactionID).then((resp) => {
    mutations.setTransaction({
      transactionID,
      transaction: { state: DisputeType.ACTIVE },
    });
    return resp;
  });
};

const openDisputeWithEvidence: TransactionAction<
  {
    transactionID: string;
    details: {
      signatureImage: string;
      details: string;
      answers: Record<string, string>;
      evidenceImages: { key: string; location: string }[];
    };
  },
  AxiosResponse
> = (context, { transactionID, details }) => {
  return axios
    .post(`${base}/dispute/open-with-evidence/${transactionID}`, { details })
    .then((resp) => {
      mutations.setTransaction({
        transactionID,
        transaction: { state: DisputeType.DISPUTE_OPEN },
      });
      return resp;
    });
};

const submitDisputeEvidence: TransactionAction<
  { transactionID: string; images: File[] },
  AxiosResponse
> = (_context, { transactionID, images }) => {
  const formData = new FormData();
  images.forEach((image) => {
    formData.append("image", image);
  });

  return axios.post(v2base + `/${transactionID}/dispute/evidence`, formData, {
    transformRequest: (val) => val,
    // setting the content-type to undefined makes the
    // browser fill in the correct content-type and boundaries
    // without it this line it will default to "application/json;charset=utf-8"
    // with no boundaries set and the upload will fail.
    headers: { "Content-Type": undefined },
  });
};

const getStatement: TransactionAction<{
  startDate: string;
  endDate: string;
}> = (context, { startDate, endDate }) => {
  return axios.get(base + "/statement/" + startDate + "/" + endDate);
};

const getStatementExport: TransactionAction<{
  formatOption: FormatOption;
  startDate: string;
  endDate: string;
  company?: string;
}> = (
  context,
  { formatOption, startDate, endDate, company = "Privacy.com" }
) => {
  return axios
    .get(
      base + "/export/" + formatOption.format + "/" + startDate + "/" + endDate,
      { responseType: "arraybuffer" }
    )
    .then((result) => {
      const type = result.headers["content-type"];
      const blob = new Blob([result.data], { type: type });
      const dateRangeString =
        formatDate("MM-DD-YYYY", startDate) +
        " - " +
        formatDate("MM-DD-YYYY", endDate);

      FileSaver.saveAs(
        blob,
        company + " Statement " + dateRangeString + "." + formatOption.extension
      );
    });
};

const manuallyApproveBatch: TransactionAction<{
  latestBatchID: string;
  totalPenniesApproved: number;
}> = (context, { latestBatchID, totalPenniesApproved }) => {
  return axios.post(`${base}/batch/approve`, {
    latestBatchID,
    totalPenniesApproved,
  });
};

const getBounces: TransactionAction<void, AxiosResponse> = () => {
  return axios.get(`${base}/bounces`);
};

const retryBatch: TransactionAction<{
  batchIDs: string[];
  newBankAccountID: string;
  initiatedByUser: boolean;
}> = (context, { batchIDs, newBankAccountID, initiatedByUser = false }) => {
  return axios.post(`${base}/batch/retry`, {
    batchIDs,
    newBankAccountID,
    initiatedByUser,
  });
};

const fetchPendingTransactions: TransactionAction<void, AxiosResponse> = () => {
  return axios.get(`${v2base}/pending`);
};

export const actions = {
  fetchTransactions: builder.dispatch(fetchTransactions),
  fetchTransaction: builder.dispatch(fetchTransaction),
  cancelDispute: builder.dispatch(cancelDispute),
  hide: builder.dispatch(hide),
  getStatement: builder.dispatch(getStatement),
  getStatementExport: builder.dispatch(getStatementExport),
  openDisputeWithEvidence: builder.dispatch(openDisputeWithEvidence),
  submitDisputeEvidence: builder.dispatch(submitDisputeEvidence),
  manuallyApproveBatch: builder.dispatch(manuallyApproveBatch),
  getBounces: builder.dispatch(getBounces),
  retryBatch: builder.dispatch(retryBatch),
  fetchPendingTransactions: builder.dispatch(fetchPendingTransactions),
};
