import axios, { AxiosResponse } from "@/lib/axios";
import { FundingCard } from "@/types/Funding";
import { User } from "@/types/User";
import { ActionHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";
import { userStore } from ".";

type FundingCardStoreState = Record<string, any>;
const builder = storeBuilder.module<FundingCardStoreState>("fundingCard", {});

const base = "/api/v1/fundingcard";
const networkBase = "cards/";
const cardNetworksWithLogo = ["VISA", "MASTERCARD", "DISCOVER"];
// max from Vantiv min from us
const MIN_LOAD_AMOUNT = 5;
const MAX_LOAD_AMOUNT = 3000;

type FundingCardAction<Payload = void, Type = void> = ActionHandler<
  FundingCardStoreState,
  RootState,
  any,
  Payload,
  Type
>;

const icon = builder.read(
  () =>
    (fundingCard: any): string => {
      const network = fundingCard?.network ?? fundingCard?.fundingBrand;
      return !cardNetworksWithLogo.includes(network)
        ? ""
        : `${networkBase}${network.toLowerCase()}`;
    },
  "icon"
);

const constants = builder.read(
  () => ({
    MIN_LOAD_AMOUNT,
    MAX_LOAD_AMOUNT,
  }),
  "getConstants"
);

export const getters = {
  get icon() {
    return icon();
  },
  get constants() {
    return constants();
  },
};

const add: FundingCardAction<FundingCard> = (context, card) => {
  return axios.post(`${base}/`, card);
};

const list: FundingCardAction = () => {
  return axios.get(`${base}/`);
};

// Define here so we can reuse in other actions
const listAction = builder.dispatch(list);

const getCardFromUser: FundingCardAction<
  { user: User; uuid?: string },
  FundingCard
> = (context, { user, uuid = null }) => {
  const correctCard = (fundingCard: FundingCard) => {
    return !uuid ? fundingCard.state !== "DELETED" : fundingCard.uuid === uuid;
  };

  const fundingCardFromState = (user.fundingCardList || []).find(correctCard);
  if (fundingCardFromState && fundingCardFromState.uuid) {
    return fundingCardFromState;
  }

  return listAction().then(({ data }: any) => {
    const { fundingCardList = [] } = data;
    /**
     * If no uuid specified should be one avail card or
     * we will return the card with the matching uuid
     */
    return fundingCardList.find(correctCard);
  });
};

const validate: FundingCardAction<
  { uuid: string; amount: number },
  AxiosResponse
> = (context, { uuid, amount }) => {
  return axios.post(`${base}/validate`, { uuid, amount }).then((response) => {
    if (response.data.isPendingBusinessReview) {
      userStore.mutations.updateCurrentUser({
        isPendingBusinessReview: true,
      });
    }
    return response;
  });
};

const deleteFundingCard: FundingCardAction<{ uuid: string }> = (
  context,
  { uuid }
) => {
  return axios.post(`${base}/delete`, { uuid });
};

const load: FundingCardAction<{ uuid: string; amount: number }> = (
  context,
  { uuid, amount }
) => {
  return axios.post(`${base}/load`, { uuid, amount });
};

const withdraw: FundingCardAction<{ uuid: string; amount: number }> = (
  context,
  { uuid, amount }
) => {
  return axios.post(`${base}/withdraw`, { uuid, amount });
};

export const actions = {
  add: builder.dispatch(add),
  getCardFromUser: builder.dispatch(getCardFromUser),
  list: listAction,
  validate: builder.dispatch(validate),
  delete: builder.dispatch(deleteFundingCard),
  load: builder.dispatch(load),
  withdraw: builder.dispatch(withdraw),
};
