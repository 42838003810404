import Vue from "vue";
import { Route, NavigationGuardNext } from "vue-router";
import { loginStore, userStore } from "@/store";
import { jwtDecode, JwtPayload } from "jwt-decode";

export function navigationGuard(
  to: Route,
  from: Route,
  next: NavigationGuardNext
) {
  function redirect() {
    // Only set afterAuth if coming from a protected route, not during the redirect
    if (from.meta?.authenticate && to.name) {
      loginStore.mutations.setAfterAuth(to);
    }

    Vue.$cookies.remove("token");
    userStore.mutations.clearCurrentUser();

    next({ name: "login" });
  }

  if (to.meta?.authenticate) {
    const token = Vue.$cookies.get("token");
    if (!isValidToken(token)) {
      return redirect();
    }
    // If token is valid, allow access to protected route
    next();
    return;
  }

  // Remove trailing slash from urls, except for root path "/"
  if (to.path?.endsWith("/") && to.path !== "/") {
    return next({ path: to.path.slice(0, -1) });
  }

  next();
}

export function isValidToken(token: string): boolean {
  try {
    if (!token) {
      return false;
    }

    const decoded = jwtDecode<JwtPayload>(token);
    if (!decoded.exp) {
      return false;
    }

    // Compare timestamps in seconds
    return decoded.exp > Date.now() / 1000;
  } catch (err) {
    return false;
  }
}
