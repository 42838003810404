<template>
  <div class="reissue-list">
    <div class="spinner-wrapper" v-if="pageLoading">
      <b-spinner class="spinner" label="Loading..."></b-spinner>
    </div>
    <template v-if="!pageLoading">
      <template v-if="totalCards">
        <div class="header">
          <h2 class="title">
            {{ remainingCards }} Remaining
            {{ remainingCards === 1 ? "Card" : "Cards" }}
          </h2>
          <BaseButton variant="link" v-b-modal.reissue-reminder>
            Why were these cards closed?
          </BaseButton>
          <div class="flex-grow-1"></div>
          <BaseButton
            variant="primary"
            :disabled="allCardsUpdateLoading"
            v-if="remainingCards"
            @click="confirmReissueAll()"
          >
            <b-icon icon="arrow-repeat" />
            <span>
              Reissue {{ remainingCards === 1 ? "" : "All" }}
              {{ remainingCards }}
              {{ remainingCards === 1 ? "Card" : "Cards" }}
            </span>
          </BaseButton>
          <BaseButton
            variant="success"
            class="update-complete"
            disabled
            v-if="!remainingCards"
          >
            <b-icon icon="check" />
            <span>All Cards Reissued</span>
          </BaseButton>
        </div>
      </template>
      <template v-else>
        <div class="header empty">
          <h2 class="title">
            <span
              v-if="
                user &&
                !user.chargeTermsAcceptTime &&
                user.accountPurpose !== AccountPurposes.BUSINESS
              "
            >
              You must
              <b-link to="/" aria-label="home">accept our terms</b-link>
              before you can reissue cards.
            </span>
            <span v-else>
              No Cards to Reissue.
              <b-link to="/cards">See all my cards</b-link>
            </span>
          </h2>
          <div class="flex-grow-1"></div>
        </div>
      </template>
      <div class="spacer"></div>
      <ReissueRow
        v-for="card in merchantCards"
        :key="card.cardID"
        :card="card"
        :loading="cardLoading[card.cardID]"
        @reissue="reissueCard(card.cardID)"
        @close="confirmCloseCard(card.cardID, card.cardUuid)"
        @inspect-card="inspectCard(card)"
      />
      <div class="single-use-toggle" v-if="singleUseCards.length">
        <div class="card-preview">
          <img
            class="card-stack"
            src="/assets/images/home/charge-terms/single-use-card-stack.png"
            alt=""
          />
        </div>
        <div class="show-all">
          <div class="show-all-title">
            {{ remainingSingleUseCards }} Single-Use
            {{ remainingSingleUseCards === 1 ? "Card" : "Cards" }}
          </div>
          <b-link @click="toggleSingleUse">
            {{ showingSingleUseCards ? "Hide" : "Show" }} All
            <b-icon
              :icon="showingSingleUseCards ? 'chevron-up' : 'chevron-down'"
            />
          </b-link>
        </div>
        <div class="flex-grow-1"></div>
        <BaseButton
          variant="light"
          class="reissue-single-use"
          :disabled="allCardsUpdateLoading"
          v-if="remainingSingleUseCards"
          @click="confirmReissueAllSingleUse()"
        >
          <b-icon icon="arrow-repeat" />
          <span>Reissue All</span>
        </BaseButton>
        <BaseButton
          variant="success"
          class="update-complete update-complete-single-use"
          disabled
          v-if="!remainingSingleUseCards"
        >
          <b-icon icon="check" />
          <span>All Cards Reissued</span>
        </BaseButton>
        <BaseButton
          variant="danger"
          class="close-single-use"
          :disabled="allCardsUpdateLoading"
          v-if="remainingSingleUseCards"
          @click="confirmCloseAllSingleUse()"
        >
          <SVGIcon icon="trash" />
          <span>Close All</span>
        </BaseButton>
      </div>
      <template v-if="showingSingleUseCards">
        <ReissueRow
          v-for="card in singleUseCards"
          :key="card.cardID"
          :card="card"
          :loading="cardLoading[card.cardID]"
          @reissue="reissueCard(card.cardID)"
          @close="confirmCloseCard(card.cardID, card.cardUuid)"
          @inspect-card="inspectCard(card)"
        />
      </template>
    </template>
    <BaseModal id="reissue-reminder" hide-header>
      <ChargeTermsReminder
        :reissue-helper="true"
        class="reissue-reminder"
        @complete-step="$bvModal.hide('reissue-reminder')"
      />
    </BaseModal>
    <CardInspector
      id="card-inspector"
      :cardProp="currentCard"
      @hide="handleCloseCardInspector"
    ></CardInspector>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Mixins } from "vue-property-decorator";
import {
  BIcon,
  BIconArrowRepeat,
  BIconCheck,
  BIconChevronDown,
  BIconChevronUp,
} from "bootstrap-vue";
import { userStore, cardStore, eventStore } from "@/store";
import { Card, CardType } from "@/types/Card";
import { AccountPurposes } from "@/types/User";
import { EVENTS } from "@/types/Event";
import { Confirm } from "@/mixins/Confirm";
import { Toast } from "@/mixins/Toast";
import ChargeTermsReminder from "@/views/ChargeTermsReminder.vue";
import ReissueRow from "@/components/ReissueRow.vue";
import SVGIcon from "@/components/SVGIcon.vue";
import CardInspector from "@/components/CardInspector.vue";

@Component({
  components: {
    ChargeTermsReminder,
    ReissueRow,
    SVGIcon,
    CardInspector,
    BIcon,
    BIconArrowRepeat,
    BIconCheck,
    BIconChevronDown,
    BIconChevronUp,
  },
})
export default class ReissueList extends Mixins(Confirm, Toast) {
  pageLoading = true;
  showingSingleUseCards = false;
  allCardsUpdateLoading = false;

  currentCard: Card | null = null;
  merchantCards: Card[] = [];
  singleUseCards: Card[] = [];
  cardLoading: { [key: number]: boolean } = {};
  AccountPurposes = AccountPurposes;

  get user() {
    return userStore.getters.currentUser;
  }

  get totalCards() {
    return this.merchantCards.length + this.singleUseCards.length;
  }

  get reissuedSingleUseCards() {
    const reissuedCards = this.singleUseCards.filter(
      (card) => !!card.meta?.reissuedFrom
    );
    return reissuedCards.length;
  }

  get reissuedMerchantLockedCards() {
    const reissuedCards = this.merchantCards.filter(
      (card) => !!card.meta?.reissuedFrom
    );
    return reissuedCards.length;
  }

  get remainingSingleUseCards() {
    return this.singleUseCards.length - this.reissuedSingleUseCards;
  }

  get remainingMerchantLockedCards() {
    return this.merchantCards.length - this.reissuedMerchantLockedCards;
  }

  get remainingCards() {
    return this.remainingSingleUseCards + this.remainingMerchantLockedCards;
  }

  created() {
    Promise.all([
      userStore.actions.getCurrentUser(),
      cardStore.actions.getCards({ onlyReissuable: true, skipCache: true }),
    ])
      .then(() => {
        // Using local state instead of a getter so
        // we can mutate it as cards get reissued
        // to continue showing the reissued card until
        // refresh
        cardStore.getters
          .cardList({ onlyReissuable: true })
          .all.forEach((card) => {
            if (card.type === CardType.SINGLE_USE) {
              this.singleUseCards.push(card);
            } else {
              this.merchantCards.push(card);
            }
          });
      })
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "There was a problem loading your cards"
        );
      })
      .finally(() => {
        this.pageLoading = false;
      });
  }

  inspectCard(card: Card) {
    this.currentCard = card;
    this.$bvModal.show("card-inspector");
  }

  handleCloseCardInspector() {
    this.currentCard = null;
  }

  toggleSingleUse() {
    this.showingSingleUseCards = !this.showingSingleUseCards;
  }

  findCard(cardID: number): [Card[] | null, number] {
    const merchantCardIdx = this.merchantCards.findIndex(
      (card) => String(card.cardID) === String(cardID)
    );
    const singleCardIdx = this.singleUseCards.findIndex(
      (card) => String(card.cardID) === String(cardID)
    );
    if (merchantCardIdx > -1) {
      return [this.merchantCards, merchantCardIdx];
    } else if (singleCardIdx > -1) {
      return [this.singleUseCards, singleCardIdx];
    }
    return [null, -1];
  }

  onReissueSuccess({
    oldCardID,
    newCard,
  }: {
    oldCardID: number;
    newCard: Card;
  }) {
    const [cardArr, cardIdx] = this.findCard(oldCardID);
    if (cardArr && cardIdx > -1) {
      Vue.set(cardArr, cardIdx, newCard);
    }
  }

  /**
   * Reissue cards
   */
  reissueCard(cardID: number) {
    Vue.set(this.cardLoading, cardID, true);
    const promise = cardStore.actions.reissue(cardID).then((response) => {
      Vue.set(this.cardLoading, cardID, false);
      this.onReissueSuccess({
        oldCardID: cardID,
        newCard: response.data,
      });
    });

    promise
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "There was a problem reissuing this card"
        );
      })
      .finally(() => {
        Vue.set(this.cardLoading, cardID, false);
      });

    return promise;
  }

  reissueAllSingleUse() {
    this.allCardsUpdateLoading = true;
    let promiseChain = Promise.resolve();

    this.singleUseCards.forEach((card) => {
      promiseChain = promiseChain.then(() => {
        if (card.meta?.reissuedFrom) {
          return Promise.resolve();
        }
        return this.reissueCard(card.cardID);
      });
    });

    promiseChain.then(() => {
      this.allCardsUpdateLoading = false;
    });
  }

  reissueAll() {
    this.allCardsUpdateLoading = true;
    let promiseChain = Promise.resolve();

    this.merchantCards.forEach((card) => {
      promiseChain = promiseChain.then(() => {
        if (card.meta?.reissuedFrom) {
          return Promise.resolve();
        }
        return this.reissueCard(card.cardID);
      });
    });

    promiseChain = promiseChain.then(() => {
      this.reissueAllSingleUse();
    });
  }

  confirmReissueAllSingleUse() {
    eventStore.actions.record({
      name: EVENTS.MODAL.VIEWED,
      data: {
        modalName: "Confirm Reissue All Single Use",
      },
    });

    this.confirm(
      `This will close and reissue ${
        this.remainingSingleUseCards === 1 ? "" : "all"
      } ${this.remainingSingleUseCards} remaining single use ${
        this.remainingSingleUseCards === 1 ? "card" : "cards"
      }.`,
      {
        title: `Reissue ${this.remainingSingleUseCards} ${
          this.remainingSingleUseCards === 1 ? "card" : "cards"
        }?`,
        okTitle: "Reissue!",
      }
    ).then((resp) => {
      if (resp) {
        eventStore.actions.record({
          name: EVENTS.CTA.CLICKED,
          data: {
            buttonContext: "Reissue Page",
            buttonName: "Reissue All Single Use",
          },
        });

        this.reissueAllSingleUse();
      }
    });
  }

  confirmReissueAll() {
    eventStore.actions.record({
      name: EVENTS.MODAL.VIEWED,
      data: {
        modalName: "Confirm Reissue All",
      },
    });

    this.confirm(
      `This will close and reissue ${this.remainingCards === 1 ? "" : "all"} ${
        this.remainingCards
      } remaining ${
        this.remainingCards === 1 ? "card" : "cards"
      }. Don't forget to update your payment details with ${
        this.remainingCards === 1 ? "the" : "each"
      } merchant.`,
      {
        title: `Reissue ${this.remainingCards} ${
          this.remainingCards === 1 ? "card" : "cards"
        }?`,
        okTitle: "Reissue!",
      }
    ).then((resp) => {
      if (resp) {
        eventStore.actions.record({
          name: EVENTS.CTA.CLICKED,
          data: {
            buttonContext: "Reissue Page",
            buttonName: "Reissue All",
          },
        });

        this.reissueAll();
      }
    });
  }

  /**
   * Close cards
   */
  closeCard(cardID: number, cardUuid: string) {
    Vue.set(this.cardLoading, cardID, true);
    const promise = cardStore.actions.close(cardUuid).then(() => {
      const [cardArr, cardIdx] = this.findCard(cardID);
      if (cardArr && cardIdx > -1) {
        cardArr.splice(cardIdx, 1);
      }
    });

    promise
      .catch((err) => {
        this.errorToast(
          err?.response?.data?.message ||
            "There was a problem closing this card"
        );
      })
      .finally(() => {
        Vue.set(this.cardLoading, cardID, false);
      });

    return promise;
  }

  closeAllSingleUse() {
    this.allCardsUpdateLoading = true;
    let promiseChain = Promise.resolve();

    this.singleUseCards.forEach((card) => {
      promiseChain = promiseChain.then(() => {
        if (card.meta?.reissuedFrom) {
          return Promise.resolve();
        }
        return this.closeCard(card.cardID, card.cardUuid);
      });
    });

    promiseChain.then(() => {
      this.allCardsUpdateLoading = false;
    });
  }

  confirmCloseCard(cardID: number, cardUuid: string) {
    eventStore.actions.record({
      name: EVENTS.MODAL.VIEWED,
      data: {
        modalName: "Confirm Close Reissuable Card",
      },
    });

    this.confirm("Are you sure? This can't be undone.", {
      title: "Close this card?",
    }).then((resp) => {
      if (resp) {
        eventStore.actions.record({
          name: EVENTS.CTA.CLICKED,
          data: {
            buttonContext: "Reissue Page",
            buttonName: "Close Card",
          },
        });

        this.closeCard(cardID, cardUuid);
      }
    });
  }

  confirmCloseAllSingleUse() {
    eventStore.actions.record({
      name: EVENTS.MODAL.VIEWED,
      data: {
        modalName: "Confirm Close All Single Use",
      },
    });

    this.confirm("Are you sure? This can't be undone.", {
      title: `Close ${this.remainingSingleUseCards} ${
        this.remainingSingleUseCards === 1 ? "card" : "cards"
      }?`,
    }).then((resp) => {
      if (resp) {
        eventStore.actions.record({
          name: EVENTS.CTA.CLICKED,
          data: {
            buttonContext: "Reissue Page",
            buttonName: "Close All Single Use",
          },
        });

        this.closeAllSingleUse();
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  margin: 100px auto;
  text-align: center;
}

.header {
  background: $white;
  box-shadow: 0 0 10px fade-out($gray-800, 0.8);
  height: 80px;
  width: 100vw;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;

  &.empty {
    box-shadow: none;
  }
}

.spacer {
  height: 80px;
}

.title {
  font-family: $font-stack-wes-fy;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 0;
  margin-right: 16px;
}

.single-use-toggle {
  height: 100px;
  padding: 0 30px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-shadow-faint;

  .card-preview {
    width: 280px;
    height: 99px;
    padding: 20px 0 0 20px;
    overflow: hidden;
    pointer-events: none;

    .card-stack {
      width: 260px;
    }
  }

  .show-all {
    font-size: 1rem;
    padding: 0 10px 0 20px;
  }

  .show-all-title {
    font-weight: bold;
  }

  ::v-deep .btn.reissue-single-use {
    fill: $gray-600;
    color: $gray-600;
    margin-right: 20px;
  }

  ::v-deep .btn.close-single-use {
    background: $color-privacy-red;

    &:hover:enabled {
      background-color: darken($color-privacy-red, 7.5%);
    }
  }
}

::v-deep .btn.update-complete {
  background: fade-out($color-green, 0.9);
  fill: $color-green;
  color: $color-green;
  pointer-events: none;

  &.update-complete-single-use {
    margin-right: 20px;
  }
}

.reissue-reminder {
  ::v-deep .header {
    border-radius: 1rem 1rem 0 0;
  }

  ::v-deep .charge-terms-reminder {
    margin: 40px 40px 30px;
    width: auto;
  }
}

@media only screen and (max-width: 679px) {
  .header {
    height: auto;
    padding: 20px;
    position: inherit;
  }

  .spacer {
    height: 0;
  }
}
</style>
