import axios from "@/lib/axios";
import { ActionHandler } from "./vuex-typex";

import { RootState, storeBuilder } from "./storeBuilder";

type CashbackState = Record<string, never>;

const builder = storeBuilder.module<CashbackState>("cashback", {});

type CashbackAction<Payload = void, Type = void> = ActionHandler<
  CashbackState,
  RootState,
  any,
  Payload,
  Type
>;

const base = "/api/v1/cashback";

// todo-ts: type the response
const unlockCashback: CashbackAction<{ code: string }, any> = (
  cxt,
  { code }
) => {
  return axios.post(`${base}/unlock`, { code });
};

// todo-ts: type the response
const getActiveCashbackDeal: CashbackAction<void, any> = () => {
  return axios.get(`${base}/`);
};

export const actions = {
  unlockCashback: builder.dispatch(unlockCashback),
  getActiveCashbackDeal: builder.dispatch(getActiveCashbackDeal),
};
