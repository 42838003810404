import axios from "@/lib/axios";
import ldGet from "lodash/get";
import { Tag } from "@/types/Tag";
import { Card } from "@/types/Card";
import { MutationHandler, ActionHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

type TagState = { tagList: Tag[] };

const builder = storeBuilder.module<TagState>("tag", { tagList: [] });

const getTags = builder.read(
  (state) => state.tagList.filter(({ deleted }) => !deleted),
  "getTags"
);

const getTagsByCard = builder.read(
  (state) => (card: Card) => {
    const cardTagList: string[] = ldGet<any, string, string[]>(
      card,
      "meta.tagList",
      []
    );
    return state.tagList.filter((t: Tag) => {
      return !t.deleted && cardTagList.includes(t._id);
    });
  },
  "getTagsByCard"
);

type TagAction<Payload = void, Type = void> = ActionHandler<
  TagState,
  RootState,
  any,
  Payload,
  Type
>;

type TagMutation<Payload = void> = MutationHandler<TagState, Payload>;

const base = "/api/v1/tag";

export const getters = {
  get getTags() {
    return getTags();
  },
  get getTagsByCard() {
    return getTagsByCard();
  },
};

const set: TagMutation<Tag[]> = (state, value) => {
  state.tagList = value;
};

const add: TagMutation<Tag> = (state, value) => {
  state.tagList = [...state.tagList, value];
};

const update: TagMutation<Tag> = (state, value) => {
  const listCopy = [...state.tagList];
  const tagIndex = state.tagList.findIndex((tag) => tag._id === value._id);
  listCopy[tagIndex] = value;
  state.tagList = listCopy;
};

export const mutations = {
  set: builder.commit(set),
  add: builder.commit(add),
  update: builder.commit(update),
};

const createTag: TagAction<Partial<Tag>, Tag> = (context, tag) => {
  return axios.post(base, tag).then((result) => {
    if (result.data) {
      mutations.add(result.data);
      return result.data;
    }
  });
};

const updateTag: TagAction<Partial<Tag>> = (context, tag) => {
  return axios.put(base + "/" + tag._id, tag).then((result) => {
    if (result.data) {
      mutations.update(result.data);
      return result.data;
    }
  });
};

type FetchTags = (force?: boolean) => Promise<TagState>;
function fetchAllTags() {
  let request: any = null;

  // @ts-ignore it's the tag state
  return function fetchTags({ state }, force = false) {
    if (state.tagList.length && !force) {
      return Promise.resolve(state.tagList);
    }

    if (!request) {
      request = axios
        .get(base)
        .then((response) => {
          if (response.data) {
            mutations.set(response.data);
            return response.data;
          }
        })
        .finally(() => {
          request = null;
        });
    }

    return request;
  };
}

const fetchTags = fetchAllTags();

const filterTagsByCard: TagAction<Card, Tag[]> = ({ state }, card: Card) => {
  const cardTagList: string[] = ldGet<any, string, string[]>(
    card,
    "meta.tagList",
    []
  );
  return state.tagList.filter((tag) => cardTagList.includes(tag._id));
};

export const actions = {
  fetchTags: builder.dispatch(fetchTags, "fetchTags") as FetchTags,
  createTag: builder.dispatch(createTag),
  updateTag: builder.dispatch(updateTag),
  filterTagsByCard: builder.dispatch(filterTagsByCard),
};
