<template>
  <SimplePage :fullpage="fullpage">
    <BaseForm
      @submit="submit"
      :fullpage="fullpage"
      class="signup-review-details"
      data-test="signup-review-details"
      id="signup-review-details"
    >
      <ProgressTracker
        v-if="showProgressTracker"
        :currentStep="4"
        :totalSteps="5"
      ></ProgressTracker>
      <h1 v-if="kycFailure">We couldn't verify your information</h1>
      <h1 v-else>Are you sure these details are correct?</h1>
      <p class="blurb" v-if="kycFailure">
        Please ensure that your details are correct before proceeding.
      </p>
      <div class="details" v-if="user">
        <div class="row">
          <div class="field">
            <div class="label">First Name</div>
            <div class="value">{{ user.firstName }}</div>
          </div>
          <div class="field">
            <div class="label">Middle Name</div>
            <div class="value" :class="{ '-empty': !user.middleName }">
              {{ user.middleName || "None" }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <div class="label">Last Name</div>
            <div class="value">{{ user.lastName }}</div>
          </div>
          <div class="field">
            <div class="label">Date of Birth</div>
            <div class="value">{{ formatDOB(user.dob) }}</div>
          </div>
        </div>
        <div class="field">
          <div class="label">Address</div>
          <div class="value">
            {{ formatAddress(user.address1, user.address2, user.zipcode) }}
          </div>
        </div>
        <div class="field">
          <div class="label">SSN Last 4</div>
          <div class="value">{{ user.ssnLast4 || "****" }}</div>
        </div>
      </div>
      <BaseButton
        variant="primary"
        type="submit"
        size="lg"
        :loading="loading"
        data-test="signup-review-details-form-submit"
        aria-label="submit information"
        @click="trackPrimaryClick"
      >
        Yes, this information is correct
      </BaseButton>
      <b-link
        class="edit"
        data-test="signup-review-details-edit"
        :disabled="loading"
        @click="onClickEditInformation"
        aria-label="edit information"
      >
        No, I need to edit my information
      </b-link>
    </BaseForm>
  </SimplePage>
</template>

<script lang="ts">
import { AxiosError } from "@/lib/axios";
import { Mixins, Component, Prop } from "vue-property-decorator";
import ProgressTracker from "@/components/ProgressTracker.vue";
import { userStore } from "@/store";
import { formatDate, isValidDate, utcDate } from "@/lib/dates";
import { formatAddress } from "@/util";
import { Toast } from "@/mixins/Toast";
import { onboardingStepName, OnboardingSteps } from "@/types/Onboarding";

@Component({
  components: {
    ProgressTracker,
  },
})
export default class SignupReviewDetails extends Mixins(Toast) {
  @Prop({ default: false }) fullpage!: boolean;
  @Prop({ default: false }) showProgressTracker!: boolean;
  @Prop({ default: false }) kycFailure!: boolean;

  loading = false;
  formatAddress = formatAddress;

  mounted() {
    userStore.mutations.updateCurrentUser({ isEditingDetails: false });
  }

  get user() {
    return userStore.getters.currentUser;
  }

  get trackingEvent() {
    return onboardingStepName(
      this.kycFailure
        ? OnboardingSteps.REMEDIATION_RETRY
        : OnboardingSteps.REVIEW_DETAILS
    );
  }

  formatDOB(dob: string) {
    // dob may look like "01/31/1979" or "1979-01-31T00:00:00.000Z"; if the former, don't go through
    // timezone conversion (and potentially change it to a different date), just display it
    return isValidDate(dob, "MM/DD/YYYY")
      ? dob
      : formatDate("MM/DD/YYYY", utcDate(dob));
  }

  trackPrimaryClick() {
    this.$piwik.trackClick({
      name: `${this.trackingEvent} Primary CTA`,
    });
  }

  async submit() {
    this.loading = true;

    try {
      await userStore.actions.confirmDetails();
      if (this.user?.isRemediatingKYC) {
        const response = await userStore.actions.checkRemediation();
        const remediationResult = response.data?.result;

        const nextStep =
          userStore.getters.nextRemediationStep(remediationResult);

        // If remediation isn't complete now, update step
        if (nextStep !== undefined && this.user?.isRemediatingKYC) {
          userStore.actions.updateRemediationStep(nextStep);
        }
        this.$emit("complete-step", nextStep);
      } else {
        this.$emit("complete-step");
      }
    } catch (err) {
      this.errorToast(
        (err as AxiosError<{ message?: string }>).response?.data?.message ||
          "There was an internal server error, please contact support@privacy.com"
      );
    }

    this.loading = false;
  }

  onClickEditInformation() {
    this.$piwik.trackClick({
      name: `${this.trackingEvent} Secondary CTA`,
    });

    userStore.mutations.updateCurrentUser({ isEditingDetails: true });
    this.$emit("complete-step");
  }
}
</script>
<style lang="scss" scoped>
.signup-review-details {
  h1 {
    all: unset;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 1.4;
    color: $gray-800;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > .row {
      width: 100%;
      display: flex;
      flex-grow: 1;

      > .field {
        flex-basis: 50%;
      }
    }

    .field {
      width: 100%;
      background-color: $gray-100;
      border-radius: $radius-medium;
      padding: 1em;
      border: 2px solid $white;
      overflow: hidden;

      > .label {
        color: $gray-600;
        font-size: 12px;
        font-weight: bold;
      }

      > .value {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;

        &.-empty {
          color: $gray-600;
        }
      }
    }
  }

  > .btn {
    border: 2px solid $white;
  }

  > .edit {
    color: $blue;
    padding: 1rem 2rem;
    text-align: center;
    font-weight: bold;
  }
}
</style>
