<template>
  <BaseModal
    :id="modalId"
    size="xl"
    hide-header
    modal-class="card-inspector-modal"
    @hide="handleHide"
    @show="handleShow"
    data-test="card-inspector"
  >
    <button class="modal-close" @click="closeModal(modalId)">
      <b-icon icon="x" scale="2" />
      <span class="sr-only">Close</span>
    </button>
    <div
      class="card-inspector"
      :class="{
        '-card': state !== 'TRANSACTIONS',
        '-transactions': state === 'TRANSACTIONS',
        '-zero-transactions':
          !transactionList.all.length && !transactionList.declines.length,
      }"
      v-if="card"
    >
      <div class="activity-spinner" v-if="nonBlockingLoad">
        <b-spinner />
      </div>
      <div class="tabs">
        <BaseButton
          class="back"
          variant="transparent"
          @click="closeModal(modalId)"
          aria-label="back"
        >
          <SVGIcon icon="chevron-left"></SVGIcon>
        </BaseButton>
        <div
          class="tab"
          @click="state = 'CARDS'"
          :class="{ '-active': state !== 'TRANSACTIONS' }"
        >
          Card
        </div>
        <div
          class="tab"
          @click="state = 'TRANSACTIONS'"
          :class="{ '-active': state === 'TRANSACTIONS' }"
        >
          {{ listView === "DECLINES" ? "Declines" : "Transactions" }}
        </div>
        <div
          class="list-toggle"
          v-if="state === 'TRANSACTIONS'"
          @click="toggleListView"
        >
          {{ listView === "DECLINES" ? "Transactions" : "Declines" }}
        </div>
      </div>
      <div
        class="reissue-indicator-mobile"
        v-if="
          showConsumerReissuableBanner ||
          showCommercialReissuedBanner ||
          showCommercialExpiringBanner
        "
      >
        <img
          v-if="showConsumerReissuableBanner || showCommercialExpiringBanner"
          src="/assets/images/home/charge-terms/expiring-clock.png"
          alt=""
        />
        <p v-if="showConsumerReissuableBanner">
          This card was closed automatically on 1/31 as part of our recent
          update.
          <a
            href="https://support.privacy.com/hc/en-us/categories/4414487825559-Privacy-Cards-Update-12-2021"
            target="_blank"
          >
            More Info</a
          >.
        </p>
        <p v-else-if="showCommercialExpiringBanner">
          We're closing this card on Dec 12th
        </p>
        <p v-else-if="showCommercialReissuedBanner" style="max-width: unset">
          This is a reissued card.
          <BaseButton
            variant="link"
            class="inline-button"
            @click="viewClosedCard"
            >See the old card and its transactions</BaseButton
          >
        </p>
        <div class="flex-grow-1"></div>
        <BaseButton
          v-if="showConsumerReissuableBanner || showCommercialExpiringBanner"
          variant="transparent"
          class="reissue-button"
          @click="onClickReissue"
        >
          Reissue Card
        </BaseButton>
      </div>
      <div class="card-info-wrap">
        <div
          v-if="isCategoryCard"
          class="paid-badge d-flex align-items-center justify-content-center"
        >
          <SVGIcon icon="badge" size="16"></SVGIcon>
          <span>Paid Plan Feature</span>
        </div>
        <div
          v-if="card.type === CardType.DIGITAL_WALLET"
          class="paid-badge d-flex align-items-center justify-content-center"
        >
          <b-badge class="beta-badge">BETA FEATURE</b-badge>
        </div>
        <div v-if="shouldShowDowngradeWarning" class="downgrade-warning">
          If you've recently downgraded your account to the Personal plan, your
          {{ subscriptionFeatureCardType }} Card will be disabled when your plan
          ends.
        </div>
        <PrivacyCard :cardProp="card" class="mx-auto mb-4" />
        <div v-if="showUpgradeContent" class="upgrade">
          <div class="mb-2">
            <BaseBadge variant="danger">Paused</BaseBadge>
          </div>
          <p>
            Your paid plan has ended and all
            {{ subscriptionFeatureCardType }} Cards have been disabled.
          </p>
          <BaseButton
            variant="primary"
            size="sm"
            class="mx-auto"
            :to="{ name: 'subscription-plan' }"
          >
            Upgrade to Resume Card
          </BaseButton>
        </div>
        <CardPromo :card="card" />
        <TagRow
          v-if="shouldShowTagRow"
          :cardID="card.cardID"
          :onClickTag="toggleTagMenuOpen"
        />
        <div
          class="card-info-next-steps description text-center my-4"
          v-if="isSampleCard"
        >
          <div v-if="isSampleVaultedCard">
            <h1 class="header">Here's your new card!</h1>
            <p>Update the nickname and add a spend limit if you want!</p>
          </div>
          <div v-else>
            <h1 class="header">Here's your first card!</h1>
            <p>Give it a nickname and add a spend limit if you want!</p>
          </div>
          <p>
            Activate this card and reveal the card numbers by completing your
            account setup.
          </p>
          <BaseButton
            variant="primary"
            size="lg"
            class="mx-auto"
            @click="onClickConnect"
          >
            Finish Signing Up
          </BaseButton>
        </div>
        <CardDetails
          :cardProp="card"
          :isSampleCard="isSampleCard"
          :hasMerchantSelector="hasMerchantSelector"
          :hideLimitSlider="hideLimitSlider"
          @update-card="$emit('update-card', $event)"
        />
        <div
          class="controls"
          v-if="card.state !== CardState.CLOSED"
          :class="{ '-disabled': !card.cardID }"
        >
          <BaseButton
            v-if="card.state !== CardState.PAUSED"
            variant="transparent"
            @click="pauseCard"
          >
            <SVGIcon icon="pause"></SVGIcon>
            Pause
          </BaseButton>
          <BaseButton
            v-if="card.state === CardState.PAUSED"
            variant="transparent"
            :disabled="showUpgradeContent"
            :class="{ '-disabled': showUpgradeContent }"
            @click="unpauseCard"
          >
            <SVGIcon icon="play"></SVGIcon>
            Unpause
          </BaseButton>
          <BaseButton variant="transparent" @click="confirmCloseCard">
            <SVGIcon icon="trash" />
            Close
          </BaseButton>
          <b-dropdown
            right
            dropup
            menu-class="-top"
            toggle-class="empty"
            variant="transparent"
            no-caret
            aria-label="card options"
          >
            <template #button-content>
              <SVGIcon icon="ellipsis"></SVGIcon>
            </template>
            <b-dropdown-item
              v-for="item in dropdownMenu"
              :key="item.icon"
              @click="item.onClick"
            >
              <SVGIcon :icon="item.icon"></SVGIcon>
              {{ item.label }}
            </b-dropdown-item>
          </b-dropdown>
          <TagMenu v-if="tagMenuOpen" :card="card" @close="toggleTagMenuOpen" />
        </div>
      </div>
      <section
        v-if="card.type === CardType.DIGITAL_WALLET && card.unused && !closed"
        class="digital-wallet d-flex flex-column"
      >
        <h3 class="h3">Digital Wallet Card</h3>
        <p class="text">To use this card, add it to your digital wallet.</p>
        <b-button
          variant="white"
          class="btn-guide"
          href="https://wallet.google.com/wallet/paymentmethods"
          target="_blank"
        >
          <img src="@/assets/icons/google-pay.svg" alt="" />
          <div class="divider" />
          Google Pay Guide
          <SVGIcon icon="arrow-up-right" class="ml-1 mr-0" />
        </b-button>
        <b-button
          variant="white"
          class="btn-guide"
          href="https://support.apple.com/en-us/HT204506"
          target="_blank"
        >
          <img src="@/assets/icons/apple-pay.svg" alt="" />
          <div class="divider" />
          Apple Pay Guide
          <SVGIcon icon="arrow-up-right" class="ml-1 mr-0" />
        </b-button>
        <b-button
          variant="white"
          class="btn-guide mb-0"
          href="https://www.samsung.com/us/support/answer/ANS00045170/"
          target="_blank"
        >
          <img src="@/assets/icons/samsung-pay.svg" alt="" />
          <div class="divider" />
          Samsung Pay Guide
          <SVGIcon icon="arrow-up-right" class="ml-1 mr-0" />
        </b-button>
      </section>
      <section v-else-if="card.created" class="card-transactions-modal">
        <div
          v-if="isCategoryCard"
          class="category-descriptor d-flex align-items-center"
        >
          <SVGIcon icon="padlock" />
          <div class="d-flex flex-column align-items-start">
            <b-badge variant="light">{{
              merchantCategory?.name.toUpperCase()
            }}</b-badge>
            <span>{{ merchantCategory?.description }}</span>
          </div>
        </div>
        <div
          class="reissue-indicator"
          v-if="
            showConsumerReissuableBanner ||
            showCommercialReissuedBanner ||
            showCommercialExpiringBanner
          "
        >
          <img
            v-if="showConsumerReissuableBanner || showCommercialExpiringBanner"
            src="/assets/images/home/charge-terms/expiring-clock.png"
            alt=""
          />
          <p v-if="showConsumerReissuableBanner">
            This card was closed automatically on 1/31 as part of our recent
            update.
            <a
              href="https://support.privacy.com/hc/en-us/categories/4414487825559-Privacy-Cards-Update-12-2021"
              target="_blank"
            >
              More Info</a
            >.
          </p>
          <p v-else-if="showCommercialExpiringBanner">
            We're closing this card on Dec 12th
          </p>
          <p v-else-if="showCommercialReissuedBanner" style="max-width: unset">
            This is a reissued card.
            <BaseButton
              variant="link"
              class="inline-button"
              @click="viewClosedCard"
              >See the old card and its transactions</BaseButton
            >
          </p>
          <div class="flex-grow-1"></div>
          <BaseButton
            v-if="showConsumerReissuableBanner || showCommercialExpiringBanner"
            variant="transparent"
            class="reissue-button"
            @click="onClickReissue"
          >
            Reissue Card
          </BaseButton>
        </div>
        <div
          v-if="card.unused && isVaultedCard && !closed"
          class="d-flex flex-column align-items-center justify-content-center h-100 description"
        >
          <img
            src="/assets/images/misc/cards-with-arrows.svg"
            alt=""
            class="mb-4"
          />
          <h1 class="header">No transactions on this card</h1>
          <p>
            Make sure you've updated your billing info at this merchant to use
            this card
          </p>
          <BaseButton
            v-if="billingLink"
            variant="primary"
            size="lg"
            :href="billingLink.link"
            target="_blank"
          >
            Add to {{ billingLink.name || hostname }}
            <b-icon icon="box-arrow-up-right" class="ml-2" />
          </BaseButton>
        </div>
        <div v-else class="transaction-list-container">
          <div
            class="digital-wallet-banner mb-2"
            v-if="card.type === CardType.DIGITAL_WALLET && !closed"
          >
            <div>
              To use this your digital wallet card, add it to your mobile
              wallet.
            </div>
            <div class="dwb-content">
              <b-link
                href="https://wallet.google.com/wallet/paymentmethods"
                target="_blank"
                class="dwb-link"
              >
                <img src="@/assets/icons/google-pay.svg" alt="" />
                <SVGIcon icon="arrow-up-right" class="ml-1 mr-0" />
              </b-link>
              <b-link
                href="https://support.apple.com/en-us/HT204506"
                target="_blank"
                class="dwb-link"
              >
                <img src="@/assets/icons/apple-pay.svg" alt="" />
                <SVGIcon icon="arrow-up-right" class="ml-1 mr-0" />
              </b-link>
              <b-link
                href="https://www.samsung.com/us/support/answer/ANS00045170/"
                target="_blank"
                class="dwb-link"
              >
                <img src="@/assets/icons/samsung-pay.svg" alt="" />
                <SVGIcon icon="arrow-up-right" class="ml-1 mr-0" />
              </b-link>
            </div>
            <div class="divider" />
          </div>
          <BannerExtensionInstall
            v-else-if="shouldShowExtensionBanner"
            @hide-extension-banner="hideExtensionBanner"
          />
          <div class="heading mb-2" v-if="!currentTransaction">
            <div class="title">
              {{ listView === "DECLINES" ? "Declines" : "Transactions" }}
            </div>
            <div class="list-toggle" @click="toggleListView">
              Show
              {{ listView === "DECLINES" ? "Transactions" : "Declines" }}
            </div>
          </div>
          <div ref="listParent" class="list">
            <b-spinner v-if="loadingTransaction" />
            <TransactionListComponent
              v-else-if="!currentTransaction"
              :transactionsProp="
                listView === 'DECLINES'
                  ? transactionList.declines
                  : transactionList.all
              "
              @click-transaction="inspectTransaction"
              :hideColumns="{ settlement: true }"
              :showDateBuckets="true"
              :parent="listParent"
            />
            <TransactionInspector
              v-else
              :card="card"
              :transactionProp="currentTransaction"
              @on-press-back="closeTransactionInspector"
              @close-transaction-dispute="confirmCloseTransactionDispute"
              @show-dispute-modal="showDisputeModal"
            />
          </div>
        </div>
      </section>
      <div
        class="card-explanation-modal"
        v-if="!card.cardID"
        data-test="card-explanation-modal"
      >
        <div class="description" v-if="user && user.verificationNeeded">
          <h1 class="header">Hello!</h1>
          <p>
            Unfortunately we weren't able to verify your identity based on the
            info you provided. We'll need a bit more time to have a member of
            our team review your account.
          </p>
          <p>
            This step usually takes less than 48 hours. We'll be in touch soon!
          </p>
          <p>
            You'll be able to make new Privacy Cards once we've been able to
            confirm your information.
          </p>
        </div>
        <div class="description" v-else-if="user && user.applicationDeclined">
          <h1 class="header">Thank you for applying to Privacy.com</h1>
          <p>
            Unfortunately, we are unable to approve your application at this
            time.
          </p>
          <p>
            Your Privacy Card could not be activated. If you would like to learn
            more, please visit
            <b-link @click="showReasoningModal" aria-label="learn more">
              here
            </b-link>
          </p>
        </div>
        <div
          class="description next-steps"
          :class="{ 'viewing-transactions': state === 'TRANSACTIONS' }"
          v-else
        >
          <div v-if="isSampleVaultedCard">
            <h1 class="header">Here's your new card!</h1>
            <p>Update the nickname and add a spend limit if you want!</p>
          </div>
          <div v-else>
            <h1 class="header">Here's your first card!</h1>
            <p>Give it a nickname and add a spend limit if you want!</p>
          </div>
          <p>
            Activate this card and reveal the card numbers by completing your
            account setup.
          </p>
          <BaseButton
            v-if="userCanActivateSampleCard"
            variant="primary"
            size="lg"
            @click="onClickActivateSampleCard"
            data-test="activate-card"
          >
            Activate This Card
          </BaseButton>
          <BaseButton
            v-else
            variant="primary"
            size="lg"
            @click="onClickConnect"
          >
            Finish Signing Up
          </BaseButton>
        </div>
      </div>
      <BanReasoningModal></BanReasoningModal>
      <CardSharing
        :id="`card-sharing-${uuid}-src-inspector`"
        :card="card"
        @update-card="handleCardUpdate"
      />
      <EditCardStyle
        :modalId="'edit-style' + uuid + '-src-inspector'"
        :card="card"
      />
      <BaseModal id="dispute-reason" hide-header>
        <DisputeReason
          :transaction="currentTransaction"
          @close-modal="closeModal('dispute-reason')"
        />
      </BaseModal>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import uniqueId from "lodash/uniqueId";
import ldGet from "lodash/get";
import { Component, Prop, Mixins, Watch, Ref } from "vue-property-decorator";
import { BIcon, BIconX, BIconArrowUpRight } from "bootstrap-vue";
import { EVENTS } from "@/types/Event";
import { Toast } from "@/mixins/Toast";
import { Confirm } from "@/mixins/Confirm";
import { Extension } from "@/mixins/Extension";
import { Card, CardType, CardState } from "@/types/Card";
import { Transaction, TransactionList } from "@/types/Transaction";
import { AccountPurposes } from "@/types/User";
import { MerchantCategory } from "@/types/MerchantCategory";
import { FeatureFlags } from "@/types/LaunchDarkly";
import { SubscriptionState } from "@/types/Subscription";
import SVGIcon from "./SVGIcon.vue";
import BaseButton from "./BaseButton.vue";
import PrivacyCard from "./PrivacyCard.vue";
import BannerExtensionInstall from "./banners/BannerExtensionInstall.vue";
import CardPromo from "./CardPromo.vue";
import TagRow from "./TagRow.vue";
import CardDetails from "./CardDetails.vue";
import TagMenu from "./TagMenu.vue";
import TransactionListComponent from "./TransactionList.vue";
import TransactionInspector from "./TransactionInspector.vue";
import CardSharing from "./modals/CardSharing.vue";
import EditCardStyle from "./EditCardStyle.vue";
import DisputeReason from "./DisputeReason.vue";
import BanReasoningModal from "./modals/BanReasoning.vue";
import BaseBadge from "./BaseBadge.vue";
import {
  transactionStore,
  cardStore,
  tagStore,
  userStore,
  eventStore,
  featureStore,
  subscriptionStore,
} from "../store";

@Component({
  components: {
    BannerExtensionInstall,
    BanReasoningModal,
    BaseBadge,
    BaseButton,
    BIcon,
    BIconArrowUpRight,
    BIconX,
    CardDetails,
    CardPromo,
    CardSharing,
    DisputeReason,
    EditCardStyle,
    PrivacyCard,
    SVGIcon,
    TagMenu,
    TagRow,
    TransactionInspector,
    TransactionListComponent,
  },
})
export default class CardInspector extends Mixins(Toast, Confirm, Extension) {
  @Prop({ default: "card-inspector" }) modalId!: string;
  @Prop() cardProp?: Card;
  @Prop() transactionProp?: Transaction;
  @Prop() hasMerchantSelector?: boolean;
  @Prop() hideLimitSlider?: boolean;
  @Prop() nonBlockingLoad?: boolean;

  @Ref() listParent!: HTMLDivElement;

  CardState = CardState;
  CardType = CardType;

  listView = "TRANSACTIONS";
  state = "CARDS";
  tagMenuOpen = false;
  currentTransaction: Transaction | null = null;
  uuid = uniqueId();
  billingLink: Record<string, any> | null = null;
  loadingTransaction = false;
  shouldHideExtensionBanner = !!this.$cookies.get(
    "hideExtensionBannerOnCardInspector"
  );

  get user() {
    return userStore.getters.currentUser;
  }

  get card(): Card | undefined {
    const cardID = this.cardProp?.cardID || this.transactionProp?.cardID;
    return cardID ? cardStore.getters.getCard(cardID) : this.cardProp;
  }

  get merchantCategory(): MerchantCategory {
    return this.card?.merchantCategory as MerchantCategory;
  }

  get isSampleCard(): boolean {
    // CardInspector is never shown for new cards,
    // so we can infer this is the sample card.
    return !!this.card && !this.card.created;
  }

  get isVaultedCard(): boolean {
    return !!this.card?.meta?.vaulted;
  }

  get isSampleVaultedCard(): boolean {
    return this.isSampleCard && this.isVaultedCard;
  }

  get isCategoryCard(): boolean {
    return (
      this.card?.type === CardType.UNLOCKED && !!this.card?.merchantCategory
    );
  }

  get isUnlockedCard() {
    return this.card?.type === CardType.UNLOCKED && !this.isCategoryCard;
  }

  get isSubscriptionFeatureCard(): boolean {
    return this.isCategoryCard || this.card?.type === CardType.DIGITAL_WALLET;
  }

  get subscriptionFeatureCardType(): string {
    return this.isCategoryCard ? "Category-Locked" : "Digital Wallet";
  }

  get planHasCategoryCards(): boolean {
    return !!this.subscription?.features.categoryCards;
  }

  get planHasDigitalWalletCards(): boolean {
    return !!this.subscription?.features.digitalWalletCards;
  }

  get showUpgradeContent(): boolean {
    return (
      this.card?.state === CardState.PAUSED &&
      ((!this.planHasCategoryCards && this.isCategoryCard) ||
        (!this.planHasDigitalWalletCards &&
          this.card?.type === CardType.DIGITAL_WALLET))
    );
  }

  get shouldShowDowngradeWarning(): boolean {
    return (
      this.isSubscriptionFeatureCard &&
      this.subscription?.state === SubscriptionState.CANCELLED
    );
  }

  // This applies to users who were YP'd during onboarding
  // but then cleared by CX.  They initially could not activate
  // their sample card, but now can.
  // XXX(JH): Temporarily add an exception for business users,
  // who cannot sign charge terms until we complete commercial charge migration.
  get userCanActivateSampleCard(): boolean {
    return !!(
      this.isSampleCard &&
      this.user?.hasFundingSource &&
      (this.user.chargeTermsAcceptTime ||
        this.user?.accountPurpose === "BUSINESS")
    );
  }

  get shouldShowTagRow() {
    const cardTagList =
      this.card && this.card.meta && this.card.meta.tagList
        ? this.card.meta.tagList
        : [];

    return tagStore.getters.getTags.filter((tag) =>
      cardTagList.includes(tag._id)
    ).length;
  }

  get transactionList(): TransactionList {
    const cardID = String(ldGet<any, string, string>(this, "card.cardID", ""));

    return (
      (cardID && transactionStore.getters.getTransactions({ cardID })) || {
        all: [],
        declines: [],
      }
    );
  }

  get showConsumerReissuableBanner(): boolean {
    return !!(this.card?.meta && this.card.meta.reissuable);
  }

  get canSeeCommercialReissueUI(): boolean {
    return (
      this.chargeReissueFlag &&
      this.user?.accountPurpose === AccountPurposes.BUSINESS
    );
  }

  get showCommercialReissuedBanner(): boolean {
    return (
      this.canSeeCommercialReissueUI &&
      !!(this.card?.meta && this.card.meta.reissuedFrom)
    );
  }

  get showCommercialExpiringBanner(): boolean {
    return this.canSeeCommercialReissueUI && !!this.card?.meta?.isExpiring;
  }

  get shouldShowExtensionBanner() {
    return !this.shouldHideExtensionBanner && this.canInstallExtension();
  }

  get hostname(): string {
    if (!this.card) {
      return "";
    }

    const { hostname, meta = {} } = this.card;
    return hostname || meta?.hostname || "";
  }

  get closed(): boolean {
    return this.card?.state === CardState.CLOSED;
  }

  get chargeReissueFlag() {
    return !!featureStore.getters.flag(FeatureFlags.COMMERCIAL_CHARGE_REISSUE);
  }

  get notesFlag() {
    return !!this.subscription?.features.cardNotes;
  }

  get subscription() {
    return subscriptionStore.getters.subscription;
  }

  get dropdownMenu() {
    const { type, cardID } = this.card || {};
    const menu = [];

    if (cardID) {
      menu.push({
        icon: "tag",
        label: "Tag",
        onClick: this.toggleTagMenuOpen,
      });

      if (this.notesFlag) {
        menu.push({
          icon: "speech-bubble",
          label: "Notes",
          onClick: () => {
            this.$bvModal.show("edit-notes");
          },
        });
      }
    }

    if (
      type === CardType.MERCHANT_LOCKED ||
      (type !== CardType.DIGITAL_WALLET && !this.isUnlockedCard)
    ) {
      menu.push({
        icon: "paint-roller",
        label: "Customize",
        onClick: this.onClickCustomize,
      });
    }

    if (type !== CardType.DIGITAL_WALLET) {
      menu.push({
        icon: "box-arrow-up",
        label: "Share",
        onClick: this.onClickShareCard,
      });
    }

    return menu;
  }

  hideExtensionBanner() {
    this.shouldHideExtensionBanner = true;
  }

  created() {
    this.handleCardUpdate();
    this.updateCurrentTransaction();
  }

  handleShow(event: any) {
    this.tagMenuOpen = false;

    // `handleShow` is fired twice for some reason
    // so we need to check if the event is truthy
    // and only track the event once
    if (event) {
      this.$piwik.trackEvent("Card Inspector", "Open");
    }
  }

  handleHide(event: any) {
    this.tagMenuOpen = false;
    this.currentTransaction = null;

    // `handleHide` is fired twice for some reason
    // so we need to check if the event is truthy
    // and only track the event once
    if (event) {
      this.$piwik.trackEvent("Card Inspector", "Close");
    }

    this.$emit("hide");
  }

  viewClosedCard() {
    this.$emit("switch", this.card?.meta?.reissuedFrom);
  }

  closeModal(modalId = this.modalId): void {
    this.$bvModal.hide(modalId);
  }

  showReasoningModal() {
    this.$bvModal.show("ban-reasoning");
  }

  @Watch("transactionProp", { deep: true })
  updateCurrentTransaction() {
    this.currentTransaction = this.transactionProp || null;
  }

  @Watch("card")
  handleCardUpdate() {
    if (!this.card) return;
    tagStore.actions.fetchTags();

    eventStore.actions.record({
      name: EVENTS.CARD.VIEWED,
    });
    this.fetchTransactions();

    if (this.currentTransaction) {
      eventStore.actions.record({
        name: EVENTS.TRANSACTION.VIEWED,
      });

      this.state = "TRANSACTIONS";

      if (this.currentTransaction.accepted === false) {
        this.listView = "DECLINES";
      }
    }

    if (this.card?.created && this.card?.unused && this.isVaultedCard) {
      cardStore.actions
        .getBillingLink(this.card.cardID)
        .then((link) => {
          this.billingLink = link.info;
        })
        .catch(() => {
          /* ignore */
        });
    }
  }

  @Watch("shouldShowDowngradeWarning")
  trackDowngradeWarning() {
    if (this.shouldShowDowngradeWarning) {
      this.$piwik.trackContentImpression("Card Inspector", "Downgrade Warning");
    }
  }

  @Watch("showUpgradeContent")
  trackUpgradeContent() {
    if (this.showUpgradeContent) {
      this.$piwik.trackContentImpression(
        "Card Inspector",
        "Upgrade Content",
        "/select-plan"
      );
    }
  }

  fetchTransactions(limit?: number, offset?: number) {
    const cardID = String(ldGet<any, string, string>(this, "card.cardID", ""));
    if (!cardID) return;

    this.loadingTransaction = true;
    transactionStore.actions
      .fetchTransactions({ cardID, limit, offset })
      .catch(() => {
        if (cardID) this.errorToast("Couldn't load transactions");
      })
      .finally(() => {
        this.loadingTransaction = false;
      });
  }

  toggleListView() {
    this.listView =
      this.listView === "TRANSACTIONS" ? "DECLINES" : "TRANSACTIONS";

    this.$piwik.trackEvent("Card Inspector", "Toggle", {
      name: `View ${this.listView.toLowerCase()}`,
    });
  }

  inspectTransaction(transaction: Transaction) {
    eventStore.actions.record({
      name: EVENTS.TRANSACTION.VIEWED,
    });

    this.currentTransaction = transaction;
    this.$piwik.trackEvent("Card Inspector", "View", {
      name: "Single Transaction",
    });
  }

  closeTransactionInspector() {
    this.currentTransaction = null;

    this.$piwik.trackEvent("Card Inspector", "View", {
      name: "All Transactions",
    });
  }

  pauseCard() {
    cardStore.actions
      .pause(ldGet<any, string>(this, "card.cardUuid"))
      .then(() => {
        this.$emit("card-paused");
        this.successToast("Card Paused");
      })
      .catch(({ data }) => {
        this.errorToast(
          ldGet<any, any, string>(data, "message", "Failed to pause card")
        );
      });
  }

  unpauseCard() {
    cardStore.actions
      .unpause(ldGet<any, string>(this, "card.cardUuid"))
      .then(() => {
        this.$emit("card-unpaused");
        this.successToast("Card Resumed");
      })
      .catch((err) => {
        this.errorToast(
          ldGet<any, any, string>(
            err,
            "response.data.message",
            "Failed to unpause card"
          )
        );
      });
  }

  confirmCloseCard() {
    this.confirm("Are you sure? This can't be undone.", {
      title: "Close this card?",
    }).then((resp) => {
      if (resp) this.closeCard();
    });
  }

  closeCard() {
    if (!this.card?.cardUuid) {
      return;
    }
    cardStore.actions
      .close(this.card.cardUuid)
      .then(() => {
        this.$emit("card-closed");
        this.successToast("Card Closed");
      })
      .catch(({ data }) => {
        this.errorToast(
          ldGet<any, any, string>(data, "message", "Failed to close card")
        );
      });
  }

  confirmCloseTransactionDispute(transaction: Transaction) {
    this.confirm("Are you sure?", {
      title: "Close Dispute?",
    }).then((resp) => {
      if (resp) this.closeTransactionDispute(transaction);
    });
  }

  showDisputeModal() {
    this.$bvModal.show("dispute-reason");
  }

  closeTransactionDispute(transaction: Transaction) {
    transactionStore.actions.cancelDispute(transaction.transactionID);
  }

  onClickConnect() {
    this.$emit("start-onboarding");
    this.closeModal();
  }

  onClickCustomize() {
    this.$bvModal.show("edit-style" + this.uuid + "-src-inspector");
  }

  onClickShareCard() {
    this.$bvModal.show(`card-sharing-${this.uuid}-src-inspector`);
  }

  onClickReissue() {
    const route =
      this.user?.accountPurpose === AccountPurposes.BUSINESS
        ? "charge-reissue"
        : "reissue";
    eventStore.actions.record({
      name: EVENTS.CTA.CLICKED,
      data: {
        buttonContext: "Card Inspector",
        buttonName: "Start Reissue",
      },
    });

    this.$router.push({
      name: route,
    });
  }

  onClickActivateSampleCard(): void {
    cardStore.actions.activateSampleCard().then(() => {
      this.closeModal();
    });
  }

  toggleTagMenuOpen(event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    this.tagMenuOpen = !this.tagMenuOpen;
  }
}
</script>

<style lang="scss">
.card-inspector-modal {
  @media #{$media-phone} {
    transition: none !important;

    .modal-dialog {
      transition: none !important;
    }
  }

  .modal-dialog {
    max-width: 100%;

    .modal-content {
      background: none;
      width: auto;
      margin: 0 auto;

      .modal-body {
        padding: 0;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
$media-699: "only screen and (max-width: 699px)";

.card-inspector {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  background: $white;
  border-radius: $border-radius-lg;

  @media #{$media-phone} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    border-radius: 0;
    animation: none;
    overflow: scroll;
  }

  > .card-info-wrap {
    padding: 50px 40px 40px;
    max-height: 730px;
    overflow-y: scroll;
  }

  > .activity-spinner {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    margin: 0;
    height: 40px;
    width: 40px;
    border-radius: $border-radius;
    background-color: $white;
    animation: $animation-fade-in-up-zoom;
    box-shadow: $box-shadow-hairline, $box-shadow-default-faint;
  }

  > .card-info-wrap {
    display: flex;
    flex-direction: column;
    margin: auto 0;
  }

  > .card-info-wrap > .privacy-card {
    margin: 0 auto;
    margin-bottom: 20px;

    @media #{$media-phone-small} {
      margin-bottom: 20px;
    }
  }

  > .card-info-wrap > .card-details {
    margin: 0 auto;
  }

  > .tabs {
    display: none;
    position: relative;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    padding: 0 15px;
    box-shadow: inset 0 -1px 0 $color-shadow-black-faint;
    font-family: $font-stack-wes-fy;
    font-size: 20px;
    color: $gray-500;
  }

  > .tabs > .back {
    padding: 8px 10px;
    display: none;
    margin-left: -5px;
    border-radius: 50%;

    @media #{$media-phone} {
      display: initial;
    }
  }

  > .tabs > .tab {
    padding: 20px 10px;

    &.-active {
      box-shadow: inset 0 -1px 0 $gray-900;
      color: $gray-900;
    }
  }

  > .tabs > .list-toggle {
    margin: 0 10px 0 auto;
    font-family: $font-stack-lato;
    font-size: 14px;
  }

  > card-config {
    padding: 30px;
  }

  > .card-explanation-modal {
    position: relative;
  }

  > .card-transactions-modal .heading > .list-toggle {
    color: $gray-900;
  }

  > .card-transactions-modal > .reissue-indicator,
  > .reissue-indicator-mobile {
    background: linear-gradient(
        96.5deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.1) 100%
      ),
      linear-gradient(0deg, $color-green, $color-green);
    padding: 25px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-100;
    height: 80px;

    & > img {
      width: 34px;
      height: 34px;
      margin-right: 5px;
    }

    & > .spacer {
      flex-grow: 1;
    }

    & > p {
      max-width: 300px;
      margin-right: 20px;
      margin-bottom: 0;

      > a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  .card-transactions-modal .reissue-indicator ::v-deep .reissue-button.btn,
  .reissue-indicator-mobile ::v-deep .reissue-button.btn {
    width: 140px;
    background: fade-out($white, 0.8);
    color: $white;
    padding: 10px 15px;

    &:hover,
    &:focus {
      background: fade-out($white, 0.6);
      opacity: 1;
    }
  }

  .category-descriptor {
    border-radius: 16px;
    background-color: $white;
    padding: 16px;
    gap: 16px;
    margin-bottom: 24px;
  }

  ::v-deep .badge {
    padding: 4px;
  }

  .inline-button {
    color: white;
    margin: unset;
    padding: unset;
    vertical-align: baseline;
    text-decoration: underline;
  }

  > .reissue-indicator-mobile {
    display: none;
  }

  .description {
    .header {
      all: unset;
      margin-bottom: 15px;
      font-family: $font-stack-wes-fy;
      font-size: 24px;
      text-align: center;
      color: $gray-800;
      line-height: 1.4;
    }

    p {
      margin: 24px 0 0;
      font-size: 14px;
      line-height: 1.2;

      @media #{$media-phone} {
        font-size: 14px;
        font-weight: normal;
        margin-block-end: 10px;
      }
    }

    .btn {
      white-space: nowrap;
      margin-top: 24px;
      width: 172px;
      height: 48px;

      display: flex;
      justify-content: center;
      align-items: center;

      @media #{$media-phone} {
        margin-top: 15px;
      }
    }
  }

  > .card-explanation-modal {
    display: flex;
    align-items: center;
    padding: 60px 70px;
    max-width: 460px;
    background-color: fade-out($gray-300, 0.5);
    border-radius: 0 $border-radius $border-radius 0;

    @media #{$media-699} {
      box-shadow: inset 1px 0 0 0 $color-shadow-black-fainter;
      border-left: 0;
      border-radius: 0;
    }

    @media #{$media-phone} {
      padding: 20px;
      max-width: unset;
      box-shadow: $box-shadow-hairline;
    }
  }

  > .card-info-wrap > .tag-row {
    margin: 5px auto;
    width: 240px;
  }

  > .card-info-wrap > .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding-top: 10px;
    width: 100%;
    max-width: 280px;
  }
  .-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  > .card-info-wrap > .controls > .btn {
    flex-grow: 1;
    color: $gray-600;
    transition: none;
    padding: 10px 15px;
    box-shadow: none !important;
    display: flex;
    align-content: center;
    justify-content: center;

    ::v-deep .svg-icon {
      margin-right: 10px;
    }
  }

  > .card-info-wrap > .controls ::v-deep .btn {
    transition: none;

    * {
      fill: $gray-600;
    }

    &:hover {
      color: $black;

      * {
        fill: $black;
      }
    }
  }

  ::v-deep .dropdown-menu > li {
    color: $gray-600;
    transition: none;
    box-shadow: none !important;

    .svg-icon {
      margin-right: 10px;
      transition: none;

      * {
        fill: $gray-600;
      }
    }

    &:hover {
      color: $black;

      .svg-icon * {
        fill: $black;
      }
    }
  }

  .controls ::v-deep li.empty {
    padding: 10px;
    box-shadow: none !important;
  }

  > .card-info-wrap > .controls > .created {
    margin-left: auto;
    opacity: 0.4;
  }

  > .card-info-wrap > .controls .dropdown-menu {
    font-family: $font-stack-lato;

    &.-bottom::before {
      margin-right: 17px;
    }
  }

  & .tag-menu {
    margin-left: 30px;
  }

  @media only screen and (max-width: 1000px) {
    > .card-info-wrap {
      width: 50%;
    }

    > .card-info-wrap {
      display: block;
    }
  }

  @media #{$media-699} {
    flex-direction: column;
    margin-bottom: auto;

    > .tabs {
      display: flex;
    }

    > .card-transactions-modal > .reissue-indicator {
      display: none;
    }

    > .reissue-indicator-mobile {
      display: flex;
    }

    > .card-info-wrap,
    > .card-transactions-modal {
      display: none;
      width: 100%;
      max-width: 500px;
      border-left: 0;
    }

    &.-card > .card-info-wrap {
      display: block;
    }

    &.-transactions > .card-transactions-modal {
      display: initial;
      padding: 20px;
    }

    &.-transactions > .card-transactions-modal .heading {
      display: none;
    }

    &.-transactions > .reissue-indicator-mobile {
      display: none;
    }

    > .card-transactions-modal {
      margin-top: 0;
    }
  }

  @media #{$media-phone} {
    > .card-info-wrap,
    > .card-transactions-modal {
      max-width: 100%;
    }

    > .card-info-wrap > .privacy-card:hover > .card-controls {
      display: none;
    }
  }

  @media only screen and (max-width: 360px) {
    > .tabs > .tab {
      font-size: 16px;
    }

    > card-config {
      padding: 0;
    }

    &.-transactions > .card-transactions-modal {
      padding: 10px;
      width: 100%;
    }
  }
}

// Component Level
section {
  text-align: center;
  padding: 50px 40px 40px;
  background-color: fade-out($gray-300, 0.5);
  width: 590px;

  @media only screen and (max-width: 1000px) {
    width: 50%;
  }

  @media #{$media-699} {
    box-shadow: inset 1px 0 0 0 $color-shadow-black-fainter;
    width: 100%;
    border-left: 0;
    border-radius: 0;
  }

  @media #{$media-phone} {
    padding: 20px;
    box-shadow: $box-shadow-hairline;
  }
}

.h3 {
  font-size: 24px;
  font-family: $font-stack-fk-grotesk;
  color: $gray-800;
  font-weight: 600;
}

.h4 {
  font-size: 16px;
  font-family: $font-stack-lato;
  color: $gray-800;
}

.text {
  font-family: $font-stack-lato;
  font-size: 14px;
  color: #8c94a3;
}

.modal-close {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 10px;
  height: 40px;
  width: 40px;
  opacity: 0.5;
  background: none;
  border: none;

  &:hover {
    opacity: 1;
  }

  @media #{$media-phone} {
    display: none;
  }
}

// Card Info Section
.card-info-next-steps {
  display: none;

  @media #{$media-phone} {
    display: block;
  }
}

.paid-badge {
  color: $brand-purple;
  gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid $gray-300;
  margin: 0 22px 16px;
}

.beta-badge {
  border-radius: 0.25rem;
  font-family: $font-stack-graphik;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.1px;
  background-color: $brand-purple-10;
  color: $brand-purple;
}

.downgrade-warning {
  width: 240px;
  align-self: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $attention-default;
  background-color: $attention-10;
  margin-bottom: 16px;
  font-size: 12px;
  color: $foreground-default;
}

// Transaction Section
.card-transactions-modal {
  text-align: left;
  display: flex;
  flex-direction: column;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-family: $font-stack-wes-fy;
      font-size: 20px;
      line-height: 30px;
    }

    .list-toggle {
      font-weight: bold;
      color: $gray-500;
      cursor: pointer;

      &:hover {
        color: $gray-800;
      }
    }
  }

  @media #{$media-699} {
    margin-top: 30px;
    overflow: visible;

    &::before {
      display: none;
    }
  }
}

.digital-wallet-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  font-family: $font-stack-graphik;
  font-weight: 600;
  font-size: 12px;

  .dwb-content {
    display: flex;
    align-items: center;
    gap: 8px;

    .dwb-link {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 24px;
      border-radius: 8px;
      background-color: white;
      border: 1px solid $neutrals-2;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: $neutrals-2;
    margin: 24px 0;
  }
}

.transaction-list-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.list {
  flex-grow: 1;
  position: relative;
  overflow: auto;
  overflow-x: hidden;

  .transaction-list {
    position: absolute;
    width: 100%;

    @media only screen and (max-width: 1000px) {
      ::v-deep .group.left {
        flex-direction: column;
        align-items: flex-start;

        .date {
          order: 2;
        }
      }
    }

    @media #{$media-699} {
      position: static;
    }
  }

  .transaction-inspector {
    width: 100%;
  }
}

// Digital Wallet Section
.btn-guide {
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 289px;
  height: 56px;
  padding: 16px;

  img {
    margin: 0 !important;
  }

  .divider {
    width: 1px;
    height: 24px;
    background-color: $foreground-muted;
  }
}

.digital-wallet {
  background-image: url(/assets/images/privacy-card/digital-wallet-modal.svg);
  background-repeat: no-repeat;
  background-position: center 50px;

  @media #{$media-699} {
    background-image: none;
  }

  .h3 {
    margin-top: 230px;

    @media #{$media-699} {
      margin-top: 0;
    }
  }
}

// Card Explanation Section
.next-steps {
  @media #{$media-phone} {
    display: none;

    &.viewing-transactions {
      display: block;
    }
  }
}

.upgrade {
  align-self: center;
  text-align: center;
  width: 240px;
  margin-bottom: 16px;
  font-size: 12px;
  color: $foreground-default;
}
</style>
