<template>
  <div class="-refer" id="refer-page-container">
    <div class="loading-spinner" v-if="!pageLoaded">
      <b-spinner />
    </div>
    <div v-if="pageLoaded && user && promo">
      <div class="hero">
        <h1 class="heading">Refer friends to Privacy</h1>
        <p class="sub-heading" v-if="!promo.referReward">
          Refer friends by email, or share the link directly.
        </p>
        <p class="sub-heading" v-if="promo.referReward">
          Get ${{ promo.referReward }} Privacy credit when they sign up and
          spend.<br />
          They'll get ${{ promo.joinReward }} to spend anywhere online!
        </p>
      </div>
      <div class="input-bar">
        <BaseForm
          class="invite-form"
          name="emailForm"
          @submit="handleInvite"
          autocomplete="off"
        >
          <BaseInput
            class="emails"
            type="text"
            v-model="emails"
            name="emails"
            placeholder="Refer friends by email address"
          />
          <BaseButton
            class="send"
            type="submit"
            variant="white"
            aria-label="send"
          >
            <span v-if="!loadingSend">Send</span>
            <b-spinner v-if="loadingSend" small />
          </BaseButton>
        </BaseForm>
        <div class="social">
          <button
            class="link -copy"
            id="refer-link"
            v-click-to-copy="
              `https://app.privacy.com/join/${user.referralCode}`
            "
            click-to-copy-text="Referral link copied to clipboard!"
            aria-label="Copy"
          >
            <img src="/assets/images/icons/link-20.svg" width="20" alt="" />
            <b-tooltip
              target="refer-link"
              triggers="hover"
              container="refer-page-container"
            >
              Copy refer link
            </b-tooltip>
          </button>
        </div>
      </div>
      <div class="redeemed-heading">
        <h5 class="font-weight-bold">Redeemed invites</h5>
      </div>
      <div
        class="redeemed-list"
        v-if="promo.referredUsers && promo.referredUsers.length > 0"
      >
        <div
          class="item"
          v-for="(item, index) in promo.referredUsers"
          :key="index"
        >
          <div class="email">{{ item.email }}</div>
          <div class="checkmark"></div>
        </div>
      </div>
      <div class="redeemed-list" v-else>
        <div class="item -zero-state">
          Nobody has signed up from your link yet
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { BSpinner, BTooltip } from "bootstrap-vue";
import { Toast } from "@/mixins/Toast";
import { validateEmail } from "@/util";
import { Promo } from "@/types/Promo";
import { User } from "@/types/User";
import { promoStore, userStore } from "@/store";
import { AxiosError } from "@/lib/axios";

@Component({
  components: {
    BSpinner,
    BTooltip,
  },
})
export default class ReferPage extends Mixins(Toast) {
  state = "";
  promo: Partial<Promo> = {};
  emails = "";
  pageLoaded = false;
  loadingSend = false;
  user: User | null = null;

  created() {
    userStore.actions.getCurrentUser().then(() => {
      this.user = userStore.getters.currentUser;
    });

    promoStore.actions
      .getActivePromo()
      .then((response) => {
        if (!response?.data?.enabled) {
          this.$router.push({ name: "home" });
          return false;
        }

        // Allow the view to access this
        this.promo = response.data;

        const count = response.data.referredUsers.length;
        const cap = response.data.referCap;

        if (count && count < cap) {
          this.state = "SOME_REDEEMED";
        } else if (count >= cap) {
          this.state = "ALL_REDEEMED";
        } else {
          this.state = "NONE_REDEEMED";
        }
        this.pageLoaded = true;
      })
      .catch(() => {
        this.$router.push({ name: "home" });
      });
  }

  handleInvite() {
    if (!this.emails.length || this.loadingSend) {
      return;
    }

    const emailArray = this.emails
      .replace(/,/g, " ")
      .replace(/ {2,}/g, " ")
      .trim()
      .split(" ");
    const emailSendLimit = 10;

    const validatedEmails = emailArray
      .map((item) => item.trim())
      .filter(validateEmail);

    if (!validatedEmails.length) {
      this.errorToast("Couldn't find any valid emails");
      return;
    }

    if (validatedEmails.length > emailSendLimit) {
      return this.errorToast(
        "You can only invite 10 friends at once. Please remove additional emails and try again."
      );
    }

    this.loadingSend = true;

    promoStore.actions
      .sendReferInviteEmail(validatedEmails)
      .then((response) => {
        const { newlyInvitedUsers } = response.data;
        const invitedCount = newlyInvitedUsers.length;
        this.emails = "";
        this.successToast(
          `Invited ${invitedCount} friend${invitedCount > 1 ? "s" : ""}.`
        );
      })
      .catch((err: AxiosError<{ message?: string }>) => {
        const errorMessage =
          err.response?.data?.message ||
          "Error sending invites. Please try again later.";
        this.errorToast(errorMessage);
      })
      .finally(() => {
        this.loadingSend = false;
      });
  }
}
</script>

<style lang="scss" scoped>
.-refer {
  padding-top: 60px;
  display: block;
  overflow: hidden;

  @media only screen and (max-width: 780px) {
    background: $gray-100;
  }
}

.loading-spinner {
  margin-top: 100px;
  text-align: center;
}

// Hero

.hero {
  display: flex;
  flex-direction: column;
  padding: 60px 20px 0;
  background-color: $blue;
  background-image: url("/assets/images/home/refer-page/refer-page-hero.png");
  background-repeat: repeat-x;
  background-position: center top 10px;
  background-size: 1250px auto;
  text-align: center;
  color: $white;
  -webkit-font-smoothing: antialiased;

  @media #{$media-phone} {
    padding-top: 20px;
  }

  > .heading {
    margin-bottom: 20px;
    font-family: $font-stack-wes-fy;
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    @media #{$media-phone} {
      margin-bottom: 10px;
    }
  }

  > .sub-heading {
    margin-bottom: 100px;
    font-family: $font-stack-lato;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;

    @media #{$media-phone} {
      margin-bottom: 35px;
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
    }

    @media only screen and (max-width: 360px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

// Share bar container
.input-bar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  margin: -40px auto 0;
  min-height: 80px;
  max-width: 780px;
  background: $white;
  box-shadow: 0 10px 20px fade-out($blue, 0.9);
  border-radius: $border-radius;

  @media only screen and (max-width: 780px) {
    box-shadow: 0 10px 20px fade-out($gray-800, 0.95);
    border-radius: 0;
  }

  @media #{$media-phone} {
    margin-top: 0;
    flex-direction: column;
  }
}

// Email input and button
.invite-form {
  flex-direction: row;
  flex-grow: 1;
  align-items: stretch;
  padding-right: 40px;
  width: unset;
  margin: unset;

  @media #{$media-phone} {
    flex-grow: 0;
    padding-right: 20px;
  }

  > .emails {
    flex-grow: 1;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    margin-bottom: 0;

    @media #{$media-phone} {
      padding: 20px;
    }
  }

  > .send {
    all: unset;
    position: relative;
    min-width: 35px;
    font-size: 16px;
    font-weight: bold;
    color: $blue;
    &:hover {
      background-color: $white;
    }
  }
}

// Copy URL link
.social {
  display: flex;
  flex-basis: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-left: 1px solid darken($gray-100, 5%);
  font-size: 14px;

  @media #{$media-phone} {
    flex-basis: 70px;
    padding: 0 10px 0 20px;
    border-top: 1px solid darken($gray-100, 5%);
    border-left: 0;
  }

  > .link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: $gray-100;
    }
  }

  > .link.-copy {
    @media #{$media-phone} {
      flex-grow: 1;
      justify-content: center;
      margin-right: 10px;
      padding: 0 10px;
      height: 40px;
      width: auto;
      overflow: visible;
      background: $gray-100;

      img {
        display: none;
      }
    }
  }
}

// Styling for the child BaseInput
::v-deep .input-bar > .invite-form > .emails input {
  border: 0;
  padding: 20px 40px;
  background: none;
  font-family: inherit;
  font-size: 16px;
}

// Redeemed List
.redeemed-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 80px auto 0;
  max-width: 780px;

  @media only screen and (max-width: 780px) {
    margin-top: 20px;
    padding: 0 20px;
  }
}

.redeemed-list {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  max-width: 780px;
  background-color: $gray-100;
  border-radius: $border-radius;

  @media only screen and (min-width: 780px) {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: 780px) {
    background: none;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
    border-radius: 0;
  }

  > .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    line-height: 60px;

    &:not(:last-child) {
      border-bottom: 1px solid $white;
    }

    &.-zero-state {
      justify-content: center;
      padding: 40px 20px;
      line-height: normal;
      text-align: center;
      opacity: 0.5;
    }

    .name,
    .email {
      flex-basis: 260px;
      flex-grow: 1;
      flex-shrink: 1;
      padding-left: 20px;
    }

    .checkmark {
      height: 60px;
      width: 60px;
      background-image: url("/assets/images/icons/tick-20.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>
