import { LDFlagSet, LDFlagValue } from "@launchdarkly/node-server-sdk";
import axios, { AxiosRequestConfig } from "@/lib/axios";
import { FeatureFlags } from "@/types/LaunchDarkly";
import { MutationHandler, ActionHandler } from "./vuex-typex";
import { RootState, storeBuilder } from "./storeBuilder";

type LDState = {
  flags: LDFlagSet;
};

type LDAction<Payload = void, Type = void> = ActionHandler<
  LDState,
  RootState,
  any,
  Payload,
  Type
>;

type LDMutation<Payload = void> = MutationHandler<LDState, Payload>;

const builder = storeBuilder.module<LDState>("feature", {
  flags: {},
});

const base = "/api/v1/feature";

const flag = builder.read(
  (state) => (id: string) => {
    return state.flags[id];
  },
  "flag"
);

export const getters = {
  get flag() {
    return flag();
  },
};

const setAll: LDMutation<LDFlagSet> = (state, list) => {
  state.flags = list;
};

const setFlag: LDMutation<{ key: string; value: LDFlagValue }> = (
  state,
  { key, value }
) => {
  state.flags = Object.assign(state.flags, { [key]: value });
};

export const mutations = {
  setAll: builder.commit(setAll, "setAll"),
  setFlag: builder.commit(setFlag, "setFlag"),
};

function fetchAllFlags() {
  let request: any = null;

  return function fetchFlags(): LDFlagSet {
    if (!request) {
      request = axios
        .get(base + "/flags", {
          params: {
            clientSideOnly: true,
          },
        })
        .then((response) => {
          if (response.data) {
            mutations.setAll(response.data);
            return response.data;
          }
        })
        .finally(() => {
          request = null;
        });
    }

    return request;
  };
}

const fetchAll = fetchAllFlags();

const fetchFlag: LDAction<
  {
    id: FeatureFlags;
    defaultValue?: boolean;
    force?: boolean;
  },
  LDFlagValue
> = ({ state }, request) => {
  // If we already have it locally, we can generally just return
  // the value from the state
  if (state.flags[request.id] !== undefined && !request.force) {
    return Promise.resolve(state.flags[request.id]);
  }

  const params: AxiosRequestConfig["params"] = {};
  if (request.defaultValue) {
    params.default = true;
  }

  return axios
    .get(`${base}/flags/${request.id}`, { params })
    .then((response) => {
      if (response.data) {
        mutations.setFlag({
          key: request.id,
          value: response.data[request.id],
        });
        return response.data[request.id];
      }
    });
};

export const actions = {
  fetchAll: builder.dispatch(fetchAll, "fetchAll"),
  fetchFlag: builder.dispatch(fetchFlag),
};
