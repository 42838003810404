<template>
  <div>
    <div class="section-heading">
      <h2>Cashback</h2>
    </div>
    <div class="section">
      <div class="cashback" v-if="cashbackEnabled">
        <div class="description">
          You are earning {{ cashbackRate }}% cashback on every purchase!
        </div>
        <div class="offer-end-date" v-if="hasCashbackEndDate">
          Offer valid until {{ cashbackEndDate }}
        </div>
        <div class="amount">
          <div class="value">{{ totalPendingCashback }}</div>
          Pending Cashback Credit
        </div>
      </div>
      <div class="cashback" v-else>
        <p>
          Enter a cashback key here or
          <a href="/billing">upgrade your plan</a> to unlock cashback on all
          your Privacy purchases.
          <a
            href="https://support.privacy.com/hc/en-us/articles/360012750673-How-does-cashback-work"
            target="_blank"
            rel="noopener nofollow"
            >Learn more about Privacy cashback</a
          >.
        </p>
        <BaseForm @submit="onSubmitCashbackKey" class="cashback-form">
          <b-form-input
            class="input"
            placeholder="Enter Cashback Key"
            v-model="keyInput"
            label="key"
          />
          <BaseButton variant="primary" type="submit">
            Unlock Cashback
          </BaseButton>
        </BaseForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Mixins, Watch, Component } from "vue-property-decorator";
import numbro from "numbro";
import { AxiosError, AxiosResponse } from "@/lib/axios";
import { formatDate, getDate } from "@/lib/dates";
import { userStore, cashbackStore, metricsStore } from "@/store";
import { Toast } from "@/mixins/Toast";
import BaseButton from "@/components/BaseButton.vue";
import BaseForm from "@/components/BaseForm.vue";

@Component({
  components: {
    BaseButton,
    BaseForm,
  },
})
export default class Cashback extends Mixins(Toast) {
  totalPendingCashback = "0";
  loadingKeySubmit = false;
  keyInput = "";
  loadedUser = false;

  get user() {
    return userStore.getters.currentUser!;
  }

  @Watch("user", { immediate: true })
  onUserChange() {
    if (this.user && !this.loadedUser) {
      this.loadedUser = true;
      Promise.all([metricsStore.actions.getMetrics()]).then(([metricList]) => {
        this.totalPendingCashback = this.formatPennies(
          Number(metricList.totalPendingCashback || this.totalPendingCashback)
        );
      });
    }
  }

  get cashbackEnabled() {
    const endDate =
      this.user.cashbackProgram?.end &&
      getDate(this.user?.cashbackProgram?.end);
    return (
      !!this.user.cashbackProgram?.enabled &&
      (!endDate || endDate.isAfter(new Date()))
    );
  }

  get hasCashbackEndDate() {
    return !!this.user.cashbackProgram?.end;
  }

  get cashbackEndDate() {
    return (
      this.user.cashbackProgram?.end &&
      formatDate("MMMM D[,] YYYY", this.user.cashbackProgram.end)
    );
  }

  get cashbackRate() {
    return (this.user.cashbackProgram?.cashbackRate || 0.01) * 100;
  }

  formatPennies(amount: number) {
    return numbro(amount / 100).formatCurrency("0,0.00");
  }

  onSubmitCashbackKey() {
    if (this.loadingKeySubmit || !this.keyInput) {
      return;
    }

    this.loadingKeySubmit = true;

    cashbackStore.actions
      .unlockCashback({ code: this.keyInput.toUpperCase() })
      .then((response: AxiosResponse) => {
        if (!response.data) {
          return;
        }

        this.totalPendingCashback = this.formatPennies(0);

        userStore.actions.getCurrentUser().then(() => {
          this.successToast("Cashback has been unlocked!");
        });
      })
      .catch((err: AxiosError<{ message?: string }>) => {
        this.errorToast(
          err?.response?.data?.message || "You entered an invalid key."
        );
      })
      .finally(() => {
        this.loadingKeySubmit = false;
      });
  }
}
</script>
<style lang="scss" scoped>
.cashback {
  .description {
    margin: 0;
  }

  .amount {
    color: #000;
  }

  .value {
    font-size: 36px;
  }

  .cashback-form {
    padding: 0;
    max-width: 100%;

    .input {
      margin: 10px 0px 25px 0px;
      height: 60px;
      width: 100%;
      font-size: 16px;
      line-height: 30px;
      padding: 10px 25px;
      background: $white;
      border-radius: 30px;
    }
  }
}
</style>
